import React from "react";
import styles from "./About.module.css";

// import components
import CardIdentity from "./CardIdentity/CardIdentity";

function About(){
    return (
        <section className={styles.about}>
            <div className={styles.aboutContainer}>
                <div className={styles.aboutTitle}>
                    <p>Nous sommes <span>deux étudiants</span> en dernière année de <span>MMI</span></p>
                </div>
                <div className={styles.itemsContainer}>
                    <div className={styles.itemsCards}>
                        <CardIdentity name="Raphaël Tiphonet" job="Développeur web" linkedin="https://www.linkedin.com/in/raphaël-tiphonet-a28067257/" instagram="https://www.instagram.com/raph_tiph/" website="https://rtiphonet.me/" image="/assets/img/profilPicture/raphael_tiphonet.webp" alt="Photographie de Raphaël Tiphonet" />

                        <CardIdentity name="Arnaud Graciet" job="Graphiste & Vidéaste" linkedin="https://www.linkedin.com/in/arnaud-graciet/" instagram="https://www.instagram.com/arnaud_gct/" website="https://arnaudgct.fr/" image="/assets/img/profilPicture/arnaud_graciet.webp" alt="Photographie de Arnaud Graciet" />
                    </div>
                    <div className={styles.itemText}>
                        <p>Après le succès de notre application <span className={styles.spanBold}>MMI Companion</span> lancée en septembre 2023 dans le département MMI (Métiers du Multimédia et de l’Internet) de l’IUT d’Angoulême, nous avons compris qu’elle pourrait se rendre utile pour les étudiants des <span className={styles.spanBold}>autres départements</span>.<br/>
                        C’est donc dans cet objectif que nous avons réfléchi à la conception d’une nouvelle application, avec un nouveau nom et une nouvelle identitée : <span className={styles.spanBold}>"Libellule"</span>.</p>
                    </div>

                    <div className={styles.shapeTriangleTop}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="135" height="138" viewBox="0 0 135 138" fill="none">
                            <path d="M24.8827 12.5889C27.293 1.69013 40.5977 -2.51232 48.8311 5.02449L129.753 79.0998C137.987 86.6366 134.974 100.26 124.33 103.622L19.7177 136.665C9.07395 140.027 -1.21784 130.606 1.19252 119.707L24.8827 12.5889Z" stroke="var(--blue-900-80)"/>
                        </svg>
                    </div>
                    <div className={styles.shapeCircleTop}></div>
                    <div className={styles.shapeSquareTop}></div>
                    <div className={styles.shapeCircleBottom}></div>
                </div>
            </div>
        </section>
    )
}

export default About;