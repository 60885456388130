import React from "react";
import styles from "./CGU.module.css";

function CGU() {
  return (
    <div className={styles.cgu}>
      <div className={styles.cguContainer}>
        <div className={styles.cguTitle}>
          <h2>Conditions Générales d'Utilisation</h2>
          <p>En vigueur au 02/09/2024</p>
        </div>

        <article className={styles.cguItem}>
          <h3>Préambule</h3>
          <p>
            Les présentes Conditions Générales d'Utilisation (ci-après dénommées
            "CGU") encadrent l'utilisation de l'application Libellule,
            développée par Arnaud Graciet et Raphaël Tiphonet. Elles définissent
            les conditions d'accès et d'utilisation des services proposés par
            l'application par l'utilisateur. Ces CGU sont accessibles sur le
            site à la rubrique "CGU".
          </p>
          <p>
            L'inscription ou l'utilisation de l'application implique
            l'acceptation sans réserve des CGU par l'utilisateur.{" "}
            <a href="https://libellule.app/" rel="noreferrer" target="_blank">
              https://libellule.app
            </a>{" "}
            se réserve le droit de modifier unilatéralement le contenu des CGU à
            tout moment.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 1 : Mentions Légales</h3>
          <p>
            L'édition et la direction de la publication de l'application
            Libellule sont assurées par :
          </p>
          <div className={styles.cguItemContainer}>
            <div>
              <p>TIPHONET Raphaël</p>
              <p>5 ALL DES VIGNES</p>
              <p>16730 TROIS-PALIS</p>
              <p>07 71 18 59 89</p>
              <p>
                <a href="mailto:rtiphonet@gmail.com">rtiphonet@gmail.com</a>
              </p>
            </div>
            <div>
              <p>GRACIET Arnaud</p>
              <p>32 R DU PORT THUREAU</p>
              <p>16000 ANGOULEME</p>
              <p>06 52 55 15 18</p>
              <p>
                <a href="mailto:contact@arnaudgct.fr">contact@arnaudgct.fr</a>
              </p>
            </div>
          </div>
          <p>
            Libellule est hébergé sur le sol français et par la société
            française :
          </p>
          <div className={styles.cguItemContainer}>
            <div>
              <p>PULSEHEBERG</p>
              <p>9 BOULEVARD DE STRASBOURG</p>
              <p>83000 TOULON</p>
              <p>04 22 14 13 60</p>
            </div>
          </div>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 2 : Accès à l'application</h3>
          <p>
            L'application Libellule offre un accès gratuit à ses services,
            regroupant divers outils universitaires tels que l'emploi du temps,
            l'agenda, les mails, le relevé de notes ainsi que les absences (pour
            les étudiants en MMI), dans le but de simplifier la vie des
            étudiants. Cet accès est gratuit pour tout utilisateur disposant
            d'une connexion Internet, et tous les frais associés à cette
            connexion sont à la charge de l'utilisateur.
          </p>
          <p>
            Pour bénéficier des services de l'application, les utilisateurs
            non-membres doivent s'inscrire en remplissant le formulaire avec des
            informations sincères et exactes. L'accès aux fonctionnalités
            requiert une identification via un identifiant et un mot de passe,
            qui sont communiqués après l'inscription. Tout utilisateur a la
            possibilité de demander sa désinscription à tout moment en envoyant
            un mail à{" "}
            <a href="mailto:support@libellule.app">support@libellule.app</a>, il
            peut également supprimer son compte à tout moment sur l'application
            depuis son profil.
          </p>
          <p>
            Il est important de noter que l'accès à l'application est
            exclusivement réservé aux étudiants, aux professeurs et au personnel
            appartenant ou intervenant à l'IUT d'Angoulême.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 2.1 : Service Mails</h3>
          <p>
            Le service de messagerie de l'application Libellule est conçu pour
            offrir un accès sécurisé et pratique aux emails des utilisateurs. Ce
            service est entièrement gratuit et fonctionne en utilisant l'API de
            Zimbra pour la gestion et l'échange des messages. Il est important
            de noter que nos serveurs ne traitent aucun email ; tous les
            processus liés à la gestion des messages sont délégués à l'API de
            Zimbra, que vous acceptez d'utiliser lorsque vous utiliser
            l'interface web de Zimbra / Upmail.
          </p>
          <p>
            Pour garantir une connexion continue au service de messagerie et
            éviter les déconnexions fréquentes, les mots de passe des
            utilisateurs sont stockés de manière sécurisée sur leurs appareils.
            Ce stockage est réalisé dans des espaces encryptés, spécifiquement
            le Keychain Service pour les appareils Apple et le Keystore System
            pour les appareils Android. Pour des informations détaillées sur ces
            mécanismes de sécurité, veuillez consulter les documentations
            suivantes :{" "}
            <a
              href="https://developer.apple.com/documentation/security/keychain_services"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation Keychain Services pour Apple
            </a>{" "}
            et{" "}
            <a
              href="https://developer.android.com/training/articles/keystore"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation Keystore System pour Android
            </a>
            .
          </p>
          <p>
            Afin de protéger les informations sensibles et garantir le bon
            fonctionnement du service, il est impératif que les appareils des
            utilisateurs ne soient pas rootés ou jailbreakés. Un appareil
            modifié peut compromettre la sécurité des mécanismes de stockage des
            mots de passe et ainsi affecter la sécurité globale du service de
            messagerie.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 2.2 : Service Emploi du Temps</h3>
          <p>
            Le service d'emploi du temps de l'application Libellule permet aux
            utilisateurs de consulter et gérer leurs horaires universitaires de
            manière efficace. Les emplois du temps sont récupérés grâce à
            l'export iCal, une fonctionnalité intégrée dans l'outil ADE Campus
            de Adesoft.com / UPPlanning. Ce format d'exportation facilite la
            récupération et la synchronisation des données.
          </p>
          <p>
            Afin d'assurer la pérennité des données sur l'application, les
            emplois du temps sont sauvegardés à un rythme continu. Cette
            sauvegarde régulière garantit que les informations restent toujours
            à jour et disponibles pour les utilisateurs.
          </p>
          <p>
            Les données d'emploi du temps sont collectées exclusivement auprès
            des personnes ayant un compte valide à l'Université de Poitiers, via
            le système UpPlanning. Cette restriction d'accès assure que seules
            les personnes autorisées peuvent consulter les informations,
            protégeant ainsi la confidentialité des données.
          </p>
          <p>
            L'exportation des emplois du temps en format iCal, qui est intégrée
            dans l'outil ADE Campus, ne nécessite pas d'authentification
            supplémentaire. Cela permet aux utilisateurs d'intégrer facilement
            leurs horaires dans divers outils de gestion de calendrier sans
            nécessiter de connexion additionnelle.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 3 : Collecte des données</h3>
          <p>
            L'application assure la collecte et le traitement des informations
            personnelles dans le plus strict respect de la vie privée, en
            conformité avec la loi n°78-17 du 6 janvier 1978. Les données
            collectées englobent les éléments suivants :
          </p>
          <ul>
            <li>Le nom</li>
            <li>Le prénom</li>
            <li>L’adresse e-mail étudiante</li>
            <li>L'année du BUT et le numéro de TP</li>
            {/* <li>L'utilisateur peut ajouter d'autres informations personnelles visibles par les autres utilisateurs : </li>
              <ul>
                <li>Sa date de naissance</li>
                <li>Son numéro de téléphone</li>
                <li>Son site internet</li>
                <li>Ses comptes Instagram, Discord, Snapchat, TikTok</li>
              </ul> */}
          </ul>
          <p>
            Ces données sont conservées jusqu'à la fin de sa période
            universitaire. En ce qui concerne les absences et le relevé des
            notes, l'application Libellule n'y accède pas et n'enregistre pas
            ces données. Seul l'utilisateur peut y accéder. L’utilisation des
            données n'est pas à des fins commerciales.
          </p>
          <p>
            L'utilisateur dispose du droit d'accès, de rectification et
            d'opposition sur ses données personnelles, droits qu'il peut exercer
            par mail à l'adresse{" "}
            <a href="mailto:support@libellule.app">support@libellule.app</a>,
            via un formulaire de contact, ou directement depuis son espace
            personnel. La prise en charge de cette demande sera effectuée dans
            un délai maximal de 48 heures ouvrées. L'utilisateur peut également
            supprimer son compte à tout moment sur l'application dans son
            profil. Les données seront alors supprimées de manière définitive.
          </p>
          <p>
            En acceptant ces conditions, vous autorisez la collecte des données
            d’absences et de notes par le secrétariat et les enseignants de la
            formation via l’intermédiaire du site :{" "}
            <a href="https://mmi-angouleme-dashboard.alwaysdata.net/">
              https://mmi-angouleme-dashboard.alwaysdata.net/
            </a>
            , site hébergé chez :
          </p>
          <div className={styles.cguItemContainer}>
            <div>
              <p>ALWAYSDATA</p>
              <p>91 RUE DU FAUBOURG SAINT HONORE</p>
              <p>75008 PARIS 8</p>
              <p>01 84 16 23 40</p>
            </div>
          </div>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 4 : Propriété intelectuelle</h3>
          <p>
            Les contenus de l'application Libellule (marques, logos, textes,
            images, son) sont protégés par le Code de la propriété
            intellectuelle. Toute reproduction, publication ou copie nécessite
            l'autorisation préalable de l'application. L'utilisation à des fins
            commerciales et publicitaires est strictement interdite.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 5 : Responsabilité</h3>
          <p>
            Malgré les efforts déployés par l'application pour fournir des
            informations fiables, il est important de noter que leur fiabilité
            n'est pas garantie. Des défauts, erreurs ou omissions peuvent
            survenir dans les informations fournies, lesquelles sont présentées
            à titre indicatif et général. Ces données n'ont aucune valeur
            contractuelle, et l'application décline toute responsabilité en cas
            de force majeure, d'interruption ou de modification du service.
          </p>
          <p>
            Concernant le relevé de notes et les absences, il est crucial de
            souligner que seule l'information officielle délivrée par
            l'université en fin de semestre est considérée comme valide. Les
            données relatives aux notes et aux absences présentes dans
            l'application sont fournies à titre informatif uniquement et ne
            doivent en aucun cas être considérées comme exhaustives ou
            définitives.
          </p>
          <p>
            Nous déclinons toute responsabilité en cas d'erreurs qui pourraient
            survenir lors de la saisie des informations.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 6 : Liens hypertextes</h3>
          <p>
            L'application peut contenir des liens hypertextes vers des pages
            externes. L'utilisateur est informé qu'en cliquant sur ces liens, il
            quittera l'application Libellule. Cette dernière n'a aucun contrôle
            sur ces pages et ne saurait être responsable de leur contenu.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 7 : Google Analytics</h3>
          <p>
            Notre application utilise Google Analytics, un service d'analyse de
            site internet fourni par Google Inc. ('Google'). Google Analytics
            utilise des cookies, qui sont des fichiers texte placés sur votre
            ordinateur, pour aider l'application à analyser son utilisation par
            ses utilisateurs. Les données générées par les cookies concernant
            votre utilisation de l'application (y compris votre adresse IP)
            seront transmises et stockées par Google sur des serveurs situés aux
            États-Unis. Google utilisera ces informations dans le but d'évaluer
            votre utilisation de l'application, de compiler des rapports sur son
            activité à l'intention de son éditeur et de fournir d'autres
            services relatifs à son activité et à l'utilisation d'Internet.
            Google est susceptible de communiquer ces données à des tiers en cas
            d'obligation légale ou lorsque ces tiers traitent ces données pour
            le compte de Google. Google ne recoupera pas votre adresse IP avec
            d'autres données détenues par Google.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 8 : Publication par l'utilisateur</h3>
          <p>
            Les membres peuvent publier des contenus sur l'application
            Libellule, tels que des tâches ou des évaluations dans l’agenda. En
            publiant un contenu, le membre s'engage à respecter les règles de la
            Netiquette et les règles de droit en vigueur. L'application peut
            exercer une modération sur les publications et se réserve le droit
            de refuser leur mise en ligne sans justification auprès du membre.
          </p>
          <p>
            Le membre reste titulaire de l'intégralité de ses droits de
            propriété intellectuelle. En publiant un contenu sur l'application,
            il cède à la société éditrice le droit non exclusif et gratuit de
            représenter, reproduire, adapter, modifier, diffuser et distribuer
            sa publication, directement ou par un tiers autorisé, dans le monde
            entier, sur tout support (numérique ou physique), pour la durée de
            la propriété intellectuelle. Le membre cède notamment le droit
            d'utiliser sa publication sur Internet et sur les réseaux de
            téléphonie mobile.
          </p>
          <p>
            La société éditrice s'engage à faire figurer le nom du membre à
            proximité de chaque utilisation de sa publication. Tout contenu mis
            en ligne par l'utilisateur est de sa seule responsabilité.
            L'utilisateur s'engage à ne pas mettre en ligne de contenus pouvant
            porter atteinte aux intérêts de tierces personnes. Tout recours en
            justice engagé par un tiers lésé contre l'application sera pris en
            charge par l'utilisateur. Le contenu de l'utilisateur peut être à
            tout moment et pour n'importe quelle raison supprimé ou modifié par
            l'application, sans préavis.
          </p>
        </article>

        <article className={styles.cguItem}>
          <h3>Article 9 : Droit application et juridiction compétente</h3>
          <p>
            La législation française s'applique au présent contrat. En cas
            d'absence de résolution amiable d'un litige né entre les parties,
            les tribunaux français seront seuls compétents pour en connaître.
            Pour toute question relative à l'application des présentes CGU, vous
            pouvez joindre l'éditeur aux coordonnées indiquées à l'article 1.
          </p>
          <p>
            Ces Conditions Générales d'Utilisation ont été établies en accord
            avec la réglementation française et européenne en vigueur, notamment
            le RGPD, pour assurer la protection des droits et de la vie privée
            des utilisateurs de l'application Libellule.
          </p>
          <p>
            En téléchargeant l'application, vous acceptez ces CGU et vous
            reconnaissez donc avoir pris connaissance et accepté les
            dispositions qui y sont contenues.
          </p>
        </article>
      </div>
    </div>
  );
}

export default CGU;
