import React from "react";
import styles from "./Overlay.module.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

// Import components
import Arrow from "./../Scene/Arrow/Arrow";
import IsMobile from "./../../../../assets/Scripts.jsx";

function Overlay() {
  let isMobile = IsMobile(1024);

  return (
    <div className={styles.heroContainer}>
      <div className={styles.heroContent}>
        <div className={styles.heroTitle}>
          <motion.h2
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 0.5, delay: 0.2 }}
          >
            Votre <span>vie universitaire</span> dans votre smartphone
          </motion.h2>
          <motion.h3
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 0.5, delay: 0.4 }}
          >
            Une application créée par des étudiants et pour les étudiants de{" "}
            <span>l’IUT d’Angoulême</span>
          </motion.h3>
        </div>
        <div className={styles.heroButtonsContainer}>
          <div className={styles.heroButtons}>
            <NavLink to="/beta-android" className={styles.heroButtonItem}>
              <motion.img
                src="/assets/img/play_store.webp"
                alt=""
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 0.5, delay: 0.6 }}
              />
            </NavLink>
            <NavLink to="/beta-ios" className={styles.heroButtonItem}>
              <motion.img
                src="assets/img/app_store.webp"
                alt=""
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 0.5, delay: 0.8 }}
              />
            </NavLink>
          </div>
          <motion.div
            className={styles.heroButtonsDescription}
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 0.5, delay: 1.0 }}
          >
            <p>
              En téléchargeant l’app, vous acceptez les{" "}
              <NavLink to="/cgu" className={styles.applicationLink}>
                conditions générales d’utilisation
              </NavLink>
            </p>
          </motion.div>
          {isMobile ? (
            ""
          ) : (
            <Arrow
              color="#0f4199"
              name="Gratuit"
              rotate="-90"
              direction="left"
              height="-20px"
              bottom="-20px"
              right="-220px"
              delay="1.2"
            />
          )}
        </div>
        <motion.div
          className={styles.shapesContainer}
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ ease: "easeOut", duration: 0.5, delay: 0 }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="167"
            height="165"
            viewBox="0 0 167 165"
            fill="none"
          >
            <rect
              x="32.015"
              y="114.686"
              width="32.6891"
              height="32.6891"
              rx="7.5"
              transform="rotate(-38.8268 32.015 114.686)"
              stroke="white"
            />
            <circle
              cx="109.37"
              cy="69.6797"
              r="45.0412"
              transform="rotate(-72.7044 109.37 69.6797)"
              stroke="white"
            />
            <path
              d="M35.3713 88.8628C31.2551 89.8543 27.6092 86.0181 28.8086 81.9577L31.069 74.3055C32.2684 70.2451 37.4136 69.0057 40.3304 72.0747L45.8271 77.8583C48.7439 80.9272 47.2446 86.0028 43.1284 86.9943L35.3713 88.8628Z"
              stroke="white"
            />
          </svg>
        </motion.div>
      </div>
    </div>
  );
}

export default Overlay;
