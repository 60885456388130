import React, { useState, useEffect } from "react";
import styles from "./Patchnotes.module.css";
import { NavLink } from "react-router-dom";
import PatchnotesItem from "./PatchnotesItem/PatchnotesItem";

function Patchnotes() {
  return (
    <div className={styles.patchnotes}>
      <div className={styles.patchnotesContainer}>
        <div className={styles.patchnotesText}>
          <div className={styles.patchnotesTitle}>
            <h2>Journal des mises à jour</h2>
          </div>
          <div className={styles.patchnotesDescription}>
            <p>
              Notre équipe met tout en œuvre pour corriger les bugs signalés
              afin d’améliorer votre expérience sur notre application. Sur cette
              page, vous pouvez consulter les détails des modifications
              apportées lors des différentes mises à jour.
            </p>
            <p>
              Si vous rencontrez des bugs, vous pouvez nous contactez depuis la
              page{" "}
              <NavLink
                className={styles.patchnotesDescriptionLink}
                to="/contact"
              >
                contact
              </NavLink>
            </p>
          </div>
        </div>
        <div className={styles.patchnotesContentContainer}>
          {/* <PatchnotesItem
            title="Version 1.0.5"
            date="06/09/2024"
            content_Accueil={[
              "",
            ]}
            content_EmploiDuTemps={[
              "",
            ]}
            content_Agenda={[
              ""
            ]}
            content_Mails={[
              ""
            ]}
            content_Restauration={[""]}
            content_Profil={[
              ""
            ]}
            content_Scolarite={[
              ""
            ]}
            content_Promo={[""]}
            content_Autre={[
              ""
            ]}
          /> */}
          <PatchnotesItem
            title="Version 1.0.6"
            date="13/09/2024"
            content_Accueil={[
              "Affichage du nombre de tâches et d’évaluations pour les jours concernés.",
              "Le conteneur des tâches devient vert lorsqu'aucune tâche n'est prévue ou lorsque toutes les tâches ont été complétées.",
              "Suppression des deux éléments qui n’étaient pas activés.",
            ]}
            content_Restauration={[
              "La fonctionnalité a été implémentée et permet donc d'afficher le menu du Crousty pour le jour en cours et les deux jours suivants.",
              "Le menu est récupéré sur le site du Crousty et peut ne pas être à jour.",
            ]}
            content_EmploiDuTemps={[
              "En vue “jour”, la date est de nouveau centrée.",
              "La hauteur de l’emploi du temps a été réglée pour s’adapter au mieux à chaque smartphone.",
              "Ajout du groupe de classe dans le vue détaillée d’un évènement.",
            ]}
            content_Agenda={[
              "La modification des tâches a été corrigée et est maintenant fonctionnelle.",
            ]}
            content_Profil={["Ajout des paramètres de l’application."]}
            content_Parametres={[
              "Le mode sombre y a été déplacé.",
              "Les alternants ont la possibilité d’activer le “mode alternant” permettant d’avoir le calendrier d’alternance sur leur emploi du temps.",
              "En activant ce mode, ils peuvent modifier la couleur de l’évènement “Alternance” sur l’emploi du temps.",
              "Possibilité de passer en vue “semaine” par défaut dans l’emploi du temps.",
              "Possibilité de changer les couleurs des évènements par défaut dans l’emploi du temps.",
            ]}
            content_Autre={["Correction de différents bugs mineurs."]}
          />
          <PatchnotesItem
            title="Version 1.0.5"
            date="06/09/2024"
            content_Accueil={[
              "Ajout d’un lien vers “OpenWeather” en cliquant sur la météo.",
            ]}
            content_EmploiDuTemps={[
              "Correction des noms des intervenants. Pour l'instant, il est impossible de les récupérer.",
            ]}
            content_Agenda={[
              "Remise en forme et déplacement du numéro de la semaine et le bouton pour retourner à aujourd’hui.",
              "Suppression du nombre d’évaluations.",
              "Changement du nombre de tâches en une barre de progression (comme sur la page d’accueil).",
              "Ajout du nom du responsable de l’agenda.",
            ]}
            content_Mails={[
              "Correction de la requête vers l’API de Zimbra, plus de déconnexion.",
              "Les mails en version HTML, s’affiche désormais en texte brut.",
            ]}
            // content_Restauration={[""]}
            content_Profil={[
              "Ajout de la fonctionnalité permettant au responsable de l’agenda de transmettre son rôle à un autre étudiant.",
              "Remise en forme et déplacement du bouton de déconnexion en bas de la page.",
              "Ajout de l’affichage du TP, Y1 = 1er année, Y2 = 2ème année, Y3 = 3ème année.",
            ]}
            content_Scolarite={[
              "L’onglet scolarité restera inactif pour le moment.",
            ]}
            // content_Promo={[""]}
            content_Autre={[
              "Il n'est plus possible de 'spam-clicker' la réinitialisation du mot de passe.",
              "Lorsque vous êtes hors connexion, vous n'êtes plus déconnecté, mais vous arrivez sur une page hors-ligne.",
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Patchnotes;
